import React from 'react'
import Header from './Header'
import Members from './Members'
import Think from "../home/Think";

const Index = () => {
    return (
        <div>
            <Header />
            <Members />
            <Think />
        </div>
    )
}

export default Index
