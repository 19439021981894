import React from 'react'

const Header = () => {
    return (
        <div className="memeberexp-header pt-10 md:pt-20">
        <div className="pt-36 md:max-w-4xl mx-auto px-2">
          <h1 className="px-24 md:px-0 text-3xl md:text-5xl font-bold text-black text-center px-3 md:px-40">
            Level up faster with help from your peers.</h1>
            </div>
        </div>
    )
}

export default Header
