import React from "react";
import Layout from "../components/Layout";
import Index from "../components/member-experience/Index";
import { Helmet } from 'react-helmet'

const memberexperience = () => {
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Auto Genius - What Other Members Say About Auto Genius</title>
        <meta
          name="description"
          content="Hear what other automotive marketing Slack community members say about Auto Genius"
        />
      </Helmet>
      <Layout>
      <Index />
    </Layout></>
  );
};

export default memberexperience;
