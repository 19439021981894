import React, {useState} from "react";
import {FaCheck} from "@react-icons/all-files/fa/FaCheck";
import {Link} from "gatsby";

const Think = () => {
  return (
    <div className="w-full bg-yellow">
      <div className="max-w-lg mx-auto py-20 px-7">
        <h1 className="text-black text-4xl md:text-5xl font-bold text-center pb-8">
          Think you're a fit?
        </h1>
        <p className=" text-base md:text-xl text-black font-normal text-center pb-6">
          We're an invite-only automotive community welcoming members who meet
          the following criteria:
        </p>
        <div className="flex justify-center pb-3">
          <FaCheck className="text-center font-bold text-black text-5xl" />
        </div>
        <p className=" text-base md:text-xl text-black text-center italic md:px-10 pb-10">
          Marketers, Business Development, or Sales Operators.<br/><br/>
          No Vendors or Consultants. <br/><br/>
          Must be a participant, not a lurker.
        </p>
        <div className="flex justify-center mt-4 mb-0">
          <Link to="/apply-now" className="text-center rounded-md py-4 bg-black text-white font-bold cursor-pointer px-10 transition-all hover:bg-white hover:text-black">
              APPLY NOW
          </Link>
        </div>

        <p className="text-base font-medium text-black text-center italic md:px-10 leading-6 pb-3">
          
          <br />
        </p>
      </div>
    </div>
  );
};

export default Think;
